























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Home from './Home.vue'
import Create from './Create.vue'
import Edit from './Edit.vue'

@Component({
  components: {
    Home,
    Create,
    Edit
  }
})
export default class VueBlockEditor extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String }) componentId?: String

  async handleError(e: any) {
    if (
      e.graphQLErrors &&
      e.graphQLErrors.some((ge: any) => ge.path.includes('preview'))
    )
      return
    await this.$store.dispatch('snackbar/showSnackbar', {
      text: 'Error: ' + e.message,
      color: 'error'
    })
  }
}
