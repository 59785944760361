import screenshot from './screenshot.png'
import { PrefabDefinition } from '../PrefabDefinition'

export default {
  id: 'rating',
  version: 1,
  name: 'Calificación',
  description: 'Un componente que permite al usuario calificar con estrellas.',
  image: screenshot,
  docsUrl: 'https://vuetifyjs.com/en/components/ratings/',
  library: 'Vuetify',
  tag: 'v-rating',
  props: {
    color: {
      type: 'color',
      label: 'Color de las estrellas rellenas',
      optional: true
    },
    'background-color': {
      type: 'color',
      label: 'Color de las estrellas sin rellenar',
      optional: true
    }
  }
} as PrefabDefinition
