export default [
  {
    test: /./,
    title: 'General',
    attrs: {
      id: {
        type: 'string',
        label: 'Identificador',
        description: 'Identificador único del elemento (ID)'
      },
      class: {
        type: 'string',
        label: 'Clases CSS',
        description: 'Clases CSS del elemento'
      }
    },
    events: ['click']
  },
  {
    test: /^v-btn$/,
    title: 'Botón',
    attrs: {
      color: {
        type: 'color',
        label: 'Color',
        description: 'Color del botón'
      },
      to: {
        type: 'string',
        label: 'Ruta de destino',
        description: 'Ruta a la que redirige el botón'
      },
      replace: {
        type: 'checkbox',
        label: 'Reemplazar ruta en el historial',
        description:
          'Reemplaza la ruta actual en el historial al navegar (en lugar de crear una nueva entrada)'
      },
      target: {
        type: 'select',
        label: 'Abrir enlace en...',
        description:
          'Abrir el enlace en una nueva pestaña o ventana o la actual',
        fieldOptions: {
          options: [
            { value: '_blank', label: 'Nueva pestaña' },
            { value: '_self', label: 'Esta pestaña' }
          ]
        }
      },
      loading: {
        type: 'checkbox',
        label: 'Mostrar animación de carga',
        description: 'Muestra una animación de carga en el botón'
      },
      disabled: {
        type: 'checkbox',
        label: 'Deshabilitado',
        description: 'Desactiva el botón'
      },
      dark: {
        type: 'checkbox',
        label: 'Forzar Texto Blanco',
        description: 'Color oscuro del botón'
      },
      block: {
        type: 'checkbox',
        label: 'Expandir al ancho disponible',
        description: 'Expandir el botón al 100% del ancho disponible'
      },
      depressed: {
        type: 'checkbox',
        label: 'Estilo plano',
        description: 'Desactiva la sombra debajo del botón'
      },
      text: {
        type: 'checkbox',
        label: 'Sin fondo',
        description: 'Desactiva el fondo y los bordes del botón'
      },
      outlined: {
        type: 'checkbox',
        label: 'Sólo borde',
        description: 'Desactiva el fondo y la sombra del botón'
      },
      rounded: {
        type: 'checkbox',
        label: 'Bordes redondeados',
        description: 'Redondea los bordes del botón'
      },
      tile: {
        type: 'checkbox',
        label: 'Bordes cuadrados',
        description: 'Desactiva la redondez de los bordes del botón'
      },
      fab: {
        type: 'checkbox',
        label: 'Estilo FAB',
        description: 'Estilo de botón redondo grande (Floating Action Button)'
      },
      icon: {
        type: 'checkbox',
        label: 'Sólo ícono',
        description: 'Estilo de botón con sólo ícono'
      },
      elevation: {
        type: 'number',
        label: 'Elevación',
        description: 'Elevación del botón (sombra)'
      },
      'x-small': {
        type: 'checkbox',
        label: 'Tamaño extra pequeño',
        description: 'Tamaño extra pequeño del botón'
      },
      small: {
        type: 'checkbox',
        label: 'Tamaño pequeño',
        description: 'Tamaño pequeño del botón'
      },
      large: {
        type: 'checkbox',
        label: 'Tamaño grande',
        description: 'Tamaño grande del botón'
      },
      'x-large': {
        type: 'checkbox',
        label: 'Tamaño extra grande',
        description: 'Tamaño extra grande del botón'
      }
    }
  },
  {
    test: /^v-row$/,
    title: 'Fila',
    attrs: {
      align: {
        type: 'select',
        label: 'Alineación vertical',
        description: 'Alineación vertical de los elementos de la fila',
        fieldOptions: {
          options: [
            { value: 'start', label: 'Arriba' },
            { value: 'center', label: 'Centro' },
            { value: 'end', label: 'Abajo' },
            { value: 'baseline', label: 'Borde inferior' }
          ]
        }
      },
      justify: {
        type: 'select',
        label: 'Alineación horizontal',
        description: 'Alineación horizontal de los elementos de la fila',
        fieldOptions: {
          options: [
            { value: 'start', label: 'Izquierda' },
            { value: 'center', label: 'Centro' },
            { value: 'end', label: 'Derecha' },
            { value: 'space-around', label: 'Espacio alrededor' },
            { value: 'space-between', label: 'Espacio entre' }
          ]
        }
      },
      'no-gutters': {
        type: 'checkbox',
        label: 'Sin márgenes',
        description: 'Desactiva los márgenes laterales de la fila'
      }
    },
    actions: [
      {
        name: 'addColumn',
        icon: 'add',
        title: 'Agregar columna',
        action: 'insertChild',
        options: {
          nodeName: 'v-col',
          tagName: 'v-col',
          attrs: [],
          childNodes: []
        }
      }
    ]
  },
  {
    test: /^v-col$/,
    title: 'Columna',
    attrs: {
      cols: {
        type: 'number',
        label: 'Ancho',
        description: 'Ancho de la columna en el grid'
      },
      xs: {
        type: 'number',
        label: 'Ancho en XS',
        description: 'Ancho de la columna en el grid en pantallas XS'
      },
      sm: {
        type: 'number',
        label: 'Ancho en SM',
        description: 'Ancho de la columna en el grid en pantallas SM'
      },
      md: {
        type: 'number',
        label: 'Ancho en MD',
        description: 'Ancho de la columna en el grid en pantallas MD'
      },
      lg: {
        type: 'number',
        label: 'Ancho en LG',
        description: 'Ancho de la columna en el grid en pantallas LG'
      },
      xl: {
        type: 'number',
        label: 'Ancho en XL',
        description: 'Ancho de la columna en el grid en pantallas XL'
      },
      offset: {
        type: 'number',
        label: 'Offset',
        description: 'Offset de la columna en el grid'
      },
      'offset-xs': {
        type: 'number',
        label: 'Offset en XS',
        description: 'Offset de la columna en el grid en pantallas XS'
      },
      'offset-sm': {
        type: 'number',
        label: 'Offset en SM',
        description: 'Offset de la columna en el grid en pantallas SM'
      },
      'offset-md': {
        type: 'number',
        label: 'Offset en MD',
        description: 'Offset de la columna en el grid en pantallas MD'
      },
      'offset-lg': {
        type: 'number',
        label: 'Offset en LG',
        description: 'Offset de la columna en el grid en pantallas LG'
      },
      'offset-xl': {
        type: 'number',
        label: 'Offset en XL',
        description: 'Offset de la columna en el grid en pantallas XL'
      }
    }
  },
  {
    test: /^v-tabs$/,
    title: 'Pestañas',
    attrs: {
      'align-with-title': {
        type: 'checkbox',
        label: 'Alinear con título',
        description: 'Alinea las pestañas con el título'
      },
      'background-color': {
        type: 'color',
        label: 'Color de fondo',
        description: 'Color de fondo de las pestañas'
      },
      'center-active': {
        type: 'checkbox',
        label: 'Centrar activo',
        description: 'Centra la pestaña activa'
      },
      dark: {
        type: 'checkbox',
        label: 'Modo oscuro',
        description: 'Activa el modo oscuro'
      },
      'fixed-tabs': {
        type: 'checkbox',
        label: 'Pestañas fijas',
        description: 'Mantiene las pestañas fijas'
      },
      grow: {
        type: 'checkbox',
        label: 'Expandir',
        description: 'Expande las pestañas'
      },
      'icons-and-text': {
        type: 'checkbox',
        label: 'Iconos y texto',
        description: 'Muestra los iconos y el texto de las pestañas'
      },
      'icons-right': {
        type: 'checkbox',
        label: 'Iconos a la derecha',
        description: 'Muestra los iconos a la derecha de las pestañas'
      },
      right: {
        type: 'checkbox',
        label: 'A la derecha',
        description: 'Muestra las pestañas a la derecha'
      },
      'show-arrows': {
        type: 'checkbox',
        label: 'Mostrar flechas',
        description: 'Muestra las flechas de navegación'
      },
      'slider-color': {
        type: 'color',
        label: 'Color del slider',
        description: 'Color del slider'
      },
      'slider-size': {
        type: 'number',
        label: 'Tamaño del slider',
        description: 'Tamaño del slider'
      },
      vertical: {
        type: 'checkbox',
        label: 'Vertical',
        description: 'Muestra las pestañas en vertical'
      }
    },
    actions: [
      {
        name: 'addTab',
        icon: 'add',
        title: 'Agregar pestaña',
        action: 'insertChild',
        options: {
          nodeName: 'v-tab',
          tagName: 'v-tab',
          attrs: [],
          childNodes: []
        }
      }
    ]
  },
  {
    test: /^v-(text-field|textarea|select|autocomplete)$/,
    title: 'Campo de entrada',
    attrs: {
      'v-model': {
        type: 'string',
        label: 'v-model',
        description: 'Variable a la cual vincular',
        placeholder: 'variable',
        noBind: true
      },
      label: {
        type: 'string',
        label: 'Texto de Etiqueta',
        description: 'Aparece arriba del input'
      },
      'background-color': {
        type: 'color',
        label: 'Color de fondo',
        description: 'Color de fondo'
      },
      color: {
        type: 'color',
        label: 'Color de contorno',
        description: 'Color de contorno'
      },
      'clear-icon': {
        type: 'checkbox',
        label: 'Mostrar botón "Limpiar"',
        description: 'Muestra el botón "Limpiar"'
      },
      counter: {
        type: 'number',
        label: 'Valor Contador',
        description: 'Aparece al final del input'
      },
      dark: {
        type: 'checkbox',
        label: 'Modo oscuro',
        description: 'Activa el modo oscuro'
      },
      filled: {
        type: 'checkbox',
        label: 'Estilo Relleno',
        description: 'Activa el estilo relleno'
      },
      outlined: {
        type: 'checkbox',
        label: 'Estilo Contorno',
        description: 'Activa el estilo contorno'
      },
      flat: {
        type: 'checkbox',
        label: 'Estilo Plano',
        description: 'Activa el estilo plano'
      },
      solo: {
        type: 'checkbox',
        label: 'Estilo Solo',
        description: 'Activa el estilo solo'
      },
      'full-width': {
        type: 'checkbox',
        label: 'Ancho completo',
        description: 'Activa el ancho completo'
      },
      height: {
        type: 'number',
        label: 'Altura',
        description: 'Altura'
      },
      'hide-details': {
        type: 'checkbox',
        label: 'Ocultar detalles',
        description: 'Oculta los detalles'
      },
      hint: {
        type: 'string',
        label: 'Texto de Sugerencia',
        description: 'Aparece debajo del input'
      },
      'persistent-hint': {
        type: 'checkbox',
        label: 'Sugerencia persistente',
        description: 'Muestra la sugerencia todo el tiempo'
      },
      'append-icon': {
        type: 'string',
        label: 'Icono de sufijo',
        description: 'Icono de sufijo'
      },
      'append-outer-icon': {
        type: 'string',
        label: 'Icono de sufijo externo',
        description: 'Icono de sufijo externo'
      },
      'prepend-icon': {
        type: 'string',
        label: 'Icono de prefijo',
        description: 'Icono de prefijo'
      },
      'prepend-outer-icon': {
        type: 'string',
        label: 'Icono de prefijo externo',
        description: 'Icono de prefijo externo'
      },
      type: {
        type: 'string',
        label: 'Tipo',
        description: 'Tipo'
      },
      readonly: {
        type: 'checkbox',
        label: 'Solo lectura',
        description: 'Activa el modo solo lectura'
      },
      'single-line': {
        type: 'checkbox',
        label: 'Solo una línea',
        description: 'Activa el modo solo una línea'
      },
      autofocus: {
        type: 'checkbox',
        label: 'Autofocus',
        description: 'Activa el autofocus'
      }
    },
    events: ['change', 'input', 'focus', 'blur']
  }
]
