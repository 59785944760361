





















































































import { VueBlock } from '@/models'
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'

@Component({})
export default class VueBlockEditorSidebar extends Vue {
  @Prop({ type: Object, required: true }) block!: VueBlock
  @PropSync('activeTab', { type: String, required: true })
  activeSection!: string

  mainSections = [
    {
      key: 'options',
      icon: 'build',
      title: 'Opciones',
      subtitle: 'Configuración Básica'
    },
    {
      key: 'template',
      icon: 'polymer',
      title: 'Plantilla',
      subtitle: 'Template/vista del bloque'
    },
    {
      key: 'script',
      icon: 'memory',
      title: 'Script',
      subtitle: 'Lógica del bloque'
    },
    {
      key: 'style',
      icon: 'palette',
      title: 'Estilo',
      subtitle: 'Estilo del bloque'
    },
    {
      key: 'data',
      icon: 'integration_instructions',
      title: 'Data',
      subtitle: 'Data asincrona del bloque'
    }
  ]
}
