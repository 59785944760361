import screenshot from './screenshot.png'
import { PrefabDefinition } from '../PrefabDefinition'

export default {
  id: 'calendar',
  version: 1,
  name: 'Calendario',
  description: 'Un componente de calendario que permite visualizar eventos.',
  image: screenshot,
  docsUrl: 'https://vuetifyjs.com/en/components/calendars/',
  library: 'Vuetify',
  tag: 'v-calendar'
} as PrefabDefinition
