






























































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import * as prefabs from './prefabs'
import { PrefabDefinition } from './prefabs/PrefabDefinition'
import { editor } from 'monaco-editor'
import Fields from '@/components/form/Fields.vue'

@Component({
  components: {
    Fields
  }
})
export default class PrefabLibraryBrowser extends Vue {
  @Prop({ type: Boolean }) value!: boolean
  availablePrefabs = prefabs
  selectedComponent: PrefabDefinition | null = null
  componentProps: any = {}
  componentTextContent: string | null = null
  openPanels = []

  get isDrawerOpen() {
    return this.value
  }

  set isDrawerOpen(value: boolean) {
    this.$emit('input', value)
  }

  get componentPreview() {
    return {
      name: 'ComponentPreview',
      render: (h: any) => {
        if (!this.selectedComponent) return null
        return h(
          this.selectedComponent.tag,
          {
            props: this.componentProps
          },
          this.componentTextContent
        )
      }
    }
  }

  get componentCode() {
    if (!this.selectedComponent) return ''
    const el = document.createElement(this.selectedComponent.tag)
    for (const key in this.componentProps) {
      const value = this.componentProps[key]
      if (typeof value === 'string') {
        el.setAttribute(key, value)
      }
      if (typeof value === 'boolean') {
        value ? el.setAttribute(key, '') : el.removeAttribute(key)
      }
    }
    if (this.componentTextContent) {
      el.innerText = this.componentTextContent
    }
    return el.outerHTML.replace(/=""/g, '')
  }

  @Watch('componentCode')
  onComponentCodeChange() {
    this.$nextTick(() => {
      editor.colorizeElement(this.$refs.code as HTMLElement, {
        theme: 'vs-dark'
      })
    })
  }

  close() {
    this.discardComponent()
    this.isDrawerOpen = false
  }

  selectComponent(prefab: PrefabDefinition) {
    this.selectedComponent = prefab
    if (prefab.props) {
      const props: Record<string, any> = {}
      for (const prop in prefab.props) {
        const schema = prefab.props[prop]
        if (schema.editableDefaultValue) {
          props[prop] = schema.editableDefaultValue
        }
      }
      this.componentProps = props
    }
    if (prefab.textContent) {
      this.componentTextContent = prefab.textContent
    }
  }

  discardComponent() {
    this.selectedComponent = null
    this.componentProps = {}
    this.componentTextContent = null
  }

  insertComponent() {
    if (!this.selectedComponent) return
    this.$emit('insert', this.componentCode)
    this.close()
  }
}
