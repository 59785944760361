import screenshot from './screenshot.png'
import { PrefabDefinition } from '../PrefabDefinition'

export default {
  id: 'button',
  version: 1,
  name: 'Botón',
  description: 'Un componente de botón interactivo.',
  image: screenshot,
  docsUrl: 'https://vuetifyjs.com/en/components/buttons/',
  library: 'Vuetify',
  tag: 'v-btn',
  textContent: 'Contenido',
  props: {
    color: {
      type: 'color',
      editableDefaultValue: 'primary',
      label: 'Color del botón',
      optional: true
    },
    dark: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: 'Modo oscuro (forzar texto blanco)',
      optional: true
    },
    depressed: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: 'Sin relieve (plano)',
      optional: true
    },
    outlined: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: 'Sin relleno (solo borde)',
      optional: true
    },
    text: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: 'Sin relleno ni borde',
      optional: true
    },
    block: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: 'Expandir a ancho de contenedor',
      optional: true
    },
    rounded: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: 'Redondeado',
      optional: true
    },
    tile: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: 'Cuadrado',
      optional: true
    },
    'x-small': {
      type: 'checkbox',
      editableDefaultValue: false,
      label: 'Tamaño: Extra Pequeño',
      optional: true
    },
    small: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: 'Tamaño: Pequeño',
      optional: true
    },
    large: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: 'Tamaño: Grande',
      optional: true
    },
    'x-large': {
      type: 'checkbox',
      editableDefaultValue: false,
      label: 'Tamaño: Extra Grande',
      optional: true
    }
  }
} as PrefabDefinition
